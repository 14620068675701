/* eslint-disable max-len */
export default {
    global: {
        error: 'Une erreur est survenue',
        disconnect: 'Vous avez été déconnecté',
        yes: 'oui',
        no: 'non',
        loading: 'chargement...',
    },
    header: {
        home: 'Accueil',
        download: 'Téléchargez',
        smartphone: 'sur votre smartphone',
        logout: 'Déconnexion',
        explanation:
            "Bénéficiez des avantages exclusifs à l’app : accédez à l’ensemble de notre catalogue, restez notifié.e sur votre progression, échangez avec le formateur et bien d'autres choses",
    },
    emoji: {
        fingerRight: 'regarder à droite',
        calendar: 'calendrier',
    },
    loading: {
        loading: 'Chargement...',
    },
    hotline: {
        mail: 'bonjour@santeacademie.com',
        tel: '+33222444211',
    },
    page: {
        auth: {
            login: {
                title: 'Connectez-vous',
                loginButtonTitle: 'Me connecter',
                loginButtonTitleLoading: 'Connexion en cours...',
                username: 'Votre email',
                password: 'Mot de passe',
                resetPassword: 'Ré-initialiser mon mot de passe',
                credentialError: 'Vos identifiants sont invalides',
                genericError: 'Une erreur est survenue, veuillez re-essayer plus tard',
            },
            resetPassword: {
                back: 'Retour',
                title: 'Réinitialisez votre mot de passe',
                helper: "Entrez l'email associé à votre compte et nous vous enverrons un email contenant les instructions pour le réinitialiser.",
                username: 'Votre email',
                cta: 'Envoyer les instructions',
                success: {
                    validation:
                        "Un mail a vous été envoyé sur l'adresse {{email}}. Vous devriez le recevoir au plus tard dans 15 minutes.",
                    helper: {
                        title: 'Si vous ne recevez pas le message, vous pouvez tenter les actions suivantes :',
                        spams: 'vérifier dans vos spams',
                        retryLink: 'renvoyer le mail',
                        retrySentence: ' de réinitialisation',
                        hotline: {
                            mail: 'contacter le service client par mail (',
                            tel: ') ou par téléphone ',
                        },
                    },
                },
            },
            resetPasswordConfirmation: {
                back: 'Retour',
                title: 'Réinitialisez votre mot de passe',
                helper: 'Veuillez entrer votre nouveau mot de passe pour compléter la procédure de ré-initialisation.',
                password: 'Nouveau mot de passe',
                confirmation: 'Confirmation du mot de passe',
                cta: 'Ré-initialiser mon mot de passe',
                success: 'Votre mot de passe à bien été ré-initialisé, vous pouvez désormais vous connecter',
                unknownError: 'Une erreur est survenue',
                passwordMismatch: 'Les deux mots de passe ne correspondent pas.',
            },
            setupPassword: {
                pageTitle: 'Santé Académie - Bienvenue sur votre espace formation',
                title: 'Bienvenue',
                helper1: 'Bonjour',
                helper2: 'veuillez créer le mot de passe de votre espace formation',
                password: 'Mot de passe',
                confirmation: 'Confirmation du mot de passe',
                cta: 'Accéder à ma formation',
                success: 'Votre mot de passe à bien été enregistré, vous pouvez désormais vous connecter',
                unknownError: 'Une erreur est survenue',
                passwordMismatch: 'Les deux mots de passe ne correspondent pas.',
            },
        },
        home: {
            title: {
                resumeRegistration: 'Reprendre votre formation en cours',
                inProgressRegistrations: 'Formations en cours',
                upcomingRegistrations: 'Formations à venir',
                pastRegistrations: 'Formations terminées',
            },
            moreCourses: {
                registration: 'formations',
                waiting: 'vous attendent',
                choose: 'Choisir ma prochaine formation',
            },
            ongoingCourse: {
                empty: 'Choisissez votre prochaine formation ici ! 😊',
                emptyShort: 'Voir les formations',
                resumeButtonLabel: 'Reprendre la formation',
                resume: 'Reprendre',
                indemnity: 'Votre indemnité en fin de formation',
                endSeason: 'Fin de la saison {{seasonIndex}}',
                endPrefix: 'Dans',
                remaining: 'Temps restant',
                courseDate: {
                    begin: 'Début le {{date}}',
                    run: {
                        one: '1 épisode à compléter avant le %{date}',
                        other: '%{count} épisodes à compléter avant le %{date}',
                    },
                    waiting: 'Reprise le {{date}}',
                },
                progressBar: {
                    notStarted: 'Démarrer',
                    started: 'Reprendre',
                    blocked: 'Clôturée',
                    finish: 'Terminée',
                },
                notStartedAlert: "La formation n'a pas encore débuté. Revenez le {{date}}",
            },
            carousel: {
                online: {
                    title: 'Bienvenue sur Santé Académie 🤗',
                    content:
                        "\nCliquez sur votre formation en cours pour la poursuivre. Vous disposez d'un délai maximum de 3 mois pour terminer une formation sur Santé Académie.\n\nÀ tout moment, vous pouvez retrouver vos formations terminées en bas de cette page.\n\nUn souci ? Contactez notre équipe support au {{phoneLink}}",
                },
                calendar: {
                    title: 'Échangez en temps-réel avec le formateur',
                    content:
                        "Pendant toute la durée de votre formation, vous avez accès à un fil de discussion dédié qui vous permet de poser vos questions au formateur et d'échanger avec les autres apprenants en continu. Une équipe est également disponible 7j/7 en cas de besoin.",
                },
                indemnity: {
                    title: 'Recevez une indemnité',
                    content:
                        "Nos formations délivrent une attestation officielle vous permettant de valider vos obligations de formation, et de bénéficier d'une indemnité financière du DPC pour compenser le temps de travail passé à vous former.",
                },
            },
            mobileAppBanner: {
                title: 'Envie d’une meilleure expérience ?',
                explanation:
                    'Nous avons une application dédiée proposant toute l’expérience Santé Académie, c’est gratuit !',
                openApp: 'Voir ma formation sur L’APP',
                close: 'Continuer sur le navigateur',
            },
        },
        registration: {
            header: {
                statusCompleted: 'Terminée ! 🎉',
                statusOngoing: 'En cours',
                daysLeft: {
                    zero: '0 jour restant',
                    one: '1 jour restant',
                    other: '%{count} jours restants',
                },
            },
            tabs: {
                course: {
                    tabName: 'Formation',
                    hours: 'heures',
                    video: {
                        zero: '0 video',
                        one: '1 video',
                        other: '%{count} videos',
                    },
                    nextVideosUnlockBeginDate: 'Cette saison se débloquera le ',
                    nextVideosUnlockExplanation:
                        'Ce délais est imposé par le DPC pour vous permettre de mettre en pratique vos nouvelles compétences dans votre quotidien. 😉',
                    available: 'Disponible',
                    nextVideosUnlockOffset: 'apres la fin de la saison précédente',
                    courseFeedback: 'Donnez votre avis sur cette formation',
                },
                forum: {
                    tabName: 'Forum',
                    message: {
                        zero: 'Aucun message',
                        one: '1 message',
                        other: '%{count} messages',
                    },
                    answer: 'Répondre',
                    dateFromNow: 'Il y a {{date}}',
                    seeReplies: {
                        one: 'Voir la réponse',
                        other: 'Voir les {{count}} réponses',
                    },
                    replies: {
                        zero: '0 réponse',
                        one: '1 réponse',
                        other: '{{count}} réponses',
                    },
                    hideReplies: 'Fermer',
                    you: 'vous',
                    trainer: 'formateur',
                    tutor: 'tuteur',
                    inputPlaceholder: 'Rédiger un message... ',
                    inputResponsePlaceholder: 'Rédiger une réponse... ',
                    sendTo: 'Écrire à ',
                    noMoreMessage: 'Vous avez tout lu !',
                    firstTimeMessage: 'Soyez le premier à commenter',
                    hotline: {
                        1: 'Un problème ? Appelez le',
                        2: ' ou',
                        3: ' écrivez-nous.',
                    },
                    editLabel: 'Modifier',
                    validateLabel: 'Valider',
                    deleteLabel: 'Supprimer',
                    deleteWarning: 'Êtes-vous sur de vouloir supprimer ce message ?',
                    cancelLabel: 'Annuler',
                    deletedContent: 'Ce message a été supprimé par son auteur•ice',
                    filter: {
                        episode: {
                            title: 'N’afficher que les messages de cet épisode',
                        },
                    },
                    messagePrivacy: {
                        all: {
                            label: 'Tout le monde',
                        },
                        expert: {
                            label: 'Tuteurs seulement',
                        },
                        visibility: {
                            main: {
                                fullName: 'Visible par vous et {{learnerName}}. seulement',
                                expertOnly: 'Visible par vous et les experts uniquement',
                            },
                            thread: {
                                fullName: 'Cette conversation est visible par vous et {{learnerName}}. seulement',
                                expertOnly: 'Cette conversation est visible par vous et le formateur seulement',
                            },
                        },
                    },
                    thread: {
                        close: 'Fermer le fil de conversation',
                        answers: {
                            zero: 'Aucune réponse',
                            one: '1 réponse',
                            other: '%{count} réponses',
                        },
                        input: {
                            answerPlaceholder: 'Répondre',
                        },
                    },
                    forumAvailability: {
                        scheduled: 'Vous pouvez poster des messages dans le forum jusqu’à la fin de la session ({{end_at}}).',
                        endScheduledFuture: 'Vous pouvez toujours poster des messages dans le forum et accéder aux ressources.',
                        endPassed: 'Vous pouvez toujours accéder aux ressources.',
                        disabled: 'Vous ne pouvez plus participer au forum.'
                    }
                },
                resources: {
                    tabName: 'Ressources',
                    availableResources: {
                        zero: 'Aucune ressource disponible',
                        one: '1 ressource disponible',
                        other: '%{count} ressources disponibles',
                    },
                    availableOn: 'Disponible après l\'épisode {{position}} "{{name}}"',
                    associatedDocuments: 'Documents de formation',
                    beginProcedureTitle: 'Convention de début de formation',
                    completionProcedureTitle: 'Convention de fin de formation',
                    procedureToSignSubtitle: 'A signer',
                    procedureSignedSubtitle: 'Disponible au téléchargement',
                    certificateTitle: 'Attestation de formation',
                    certificateSubtitle: 'Disponible au téléchargement',
                    certificateError: 'Une erreur est survenue lors du téléchargement du document',
                    customReviewTitle: 'Bilan personnalisé',
                    customReviewSubtitle: 'Disponible au téléchargement',
                    customReviewError: 'Une erreur est survenue lors du téléchargement du document',
                    resourceError: 'Une erreur est survenue lors du téléchargement de la ressource',
                    newResourcesDividerTitle: 'Nouveaux',
                    newResourcesModal: {
                        newResourcesUnlocked: {
                            zero: '0 ressource débloquée',
                            one: '1 ressource débloquée',
                            other: '%{count} ressources débloquées',
                        },
                        newResourcesExplanation:
                            'Toutes vos ressources sont disponibles en téléchargement depuis l’onglet “Ressources”.',
                        openResourcesTabAction: 'Accéder à mes ressources',
                        goNextEpisode: "Passer à l'épisode suivant",
                    },
                },
            },
            player: {
                video: 'Vidéo',
                next: 'suivant',
                quizzLanch: 'Lancement du quizz',
                nextVideoCountdown: 'Prochaine vidéo dans :',
                maxTimeReached:
                    "Vous pourrez utiliser l'avance de 5' lorsque vous aurez visionné l'intégralité de cette séquence",
                heartbeatError: 'Une erreur est survenue avec le player',
                sessionEnded: {
                    title: 'Votre formation est terminée.',
                    subtitleFuture: 'Vous pouvez encore participer au forum jusqu\'au {{end_at}}. Par ailleurs, les ressources complémentaires de cette formation sont accessibles sans limite de temps.',
                    subtitlePassed: 'Vous ne pouvez plus participer au forum. Par ailleurs, les ressources complémentaires de cette formation sont accessibles sans limite de temps.',
                    catalogCta: 'Voir notre catalogue de formations'
                }
            },
            episoding: {
                errorOnCompletion:
                    "Votre épisode est terminé mais nous ne sommes pas parvenus à l'enregistrer. Veuillez vérifier votre connexion et tenter de recharger la page",
            },
            breakpointQuizModal: {
                question: 'Question {{index}}',
                multipleAnswers: 'Pour cette question, plusieurs réponses sont autorisées.',
                uniqueAnswer: 'Pour cette question, une seule réponse est autorisée.',
                next: 'suivant',
                validate: 'valider',
                finished: 'Terminer',
                remakeQuiz: 'Refaire le quiz',
                goodAnswers: 'de bonnes réponses',
                successRate: 'Taux de réussite',
                congratulation: 'Félicitations !',
                almost: 'Vous y êtes presque !',
                bravo: 'Bravo !',
                results: 'Résultat',
            },
            breakpointTextModal: {
                finished: 'OK',
            },
            breakpointTextInpuModal: {
                inputPlaceholder: 'Votre réponse ici',
                seeAnswer: 'voir ma réponse dans le forum',
                valide: 'valider',
                close: 'fermer',
                finished: 'Terminer',
                publishMessage: {
                    mobileWindowSize: "J'accepte que ma réponse soit visible par tous·tes sur le forum.",
                    tabletOrHigherWindowSize:
                        'Discutez avec les autres participants ! En cochant cette case, vous acceptez que votre réponse soit visible par tous·tes sur le forum.',
                },
            },
            breakpointProcedureModal: {
                redirectToSignLabel: 'Vous allez être redirigé pour signer',
                redirectToSignButton: 'Signer le document',
                redirectToSeeProcedureLabel: 'Vous avez signé le document',
                redirectToSeeProcedureButton: 'Voir le document signé',
            },
            breakpointPracticeAuditModal: {
                title: 'Audit des pratiques',
                explanations:
                    'En vous basant sur les dossiers sur lesquels vous avez basé votre audit, indiquez dans quelle mesure vous suivez les recommandations de chacun des indicateurs qualité ci-dessous',
                notApplicable: {
                    mobileWindowSize: 'Ce critère n’est pas applicable',
                    tabletOrHigherWindowSize: 'Non applicable',
                },
                practiceAuditAnswer: {
                    0: 'Jamais',
                    1: 'Parfois',
                    2: 'Souvent',
                    3: 'Toujours',
                },
            },
            breakpointIQSSModal: {
                title: 'Indicateur de qualité et de sécurité des soins',
                explanations:
                    'En vous basant sur la fiche de calcul des indicateurs (téléchargeable dans l\'onglet Ressources), reportez les valeurs que vous avez calculées pour chacun des indicateurs.',
                notApplicable: 'Non applicable',
                finished: "terminer",
                questionHelper: "Après validation, vous ne pourrez plus modifier vos indicateurs."
            },
            sign: {
                phoneFormTitle: 'Modification du téléphone',
                phoneFormCaption:
                    "Avant d'aller plus loin, il semble que votre numéro de téléphone soit invalide. Veuillez le mettre à jour.",
                header: 'Lorsque vous aurez signé, vous serez automatiquement redirigé sur votre formation',
                phonePrefixLabel: 'Indicatif pays',
                phoneNumberLabel: 'Numéro de téléphone',
                buttonLabel: 'Modifier',
                phoneInvalidError: 'Le numéro de téléphone renseigné est incorrect',
            },
            certificateDownload: {
                pageTitle: "Santé Académie - Téléchargement d'attestation",
                modalLabel: 'Si votre téléchargement ne démarre pas automatiquement, cliquez sur le bouton ci-dessous.',
                modalButton: "Téléchargement de l'attestation",
                modalHelp: 'Après le téléchargement, vous serez redirigez vers la formation.',
            },
            customReviewDownload: {
                pageTitle: 'Santé Académie - Téléchargement du bilan personnalisé',
                modalLabel: 'Si votre téléchargement ne démarre pas automatiquement, cliquez sur le bouton ci-dessous.',
                modalButton: 'Téléchargement du bilan personnalisé',
                modalHelp: 'Après le téléchargement, vous serez redirigez vers la formation.',
            },
            feedback: {
                errorOnCompletion: 'Une erreur est survenu lors de la complétion, tentez de rafraichir la page',
                previousButton: 'précédent',
                nextButton: 'suivant',
                grades: {
                    title: 'Dites-nous ce que vous avez pensé de chaque aspect de la formation',
                    explanations:
                        'Vos notes permettent d’aider les autres professionnels de santé dans leurs choix de formation, et nous aident à rendre nos prochaines formations encore meilleures ! ✨',
                    comment: {
                        title: 'Aidez-nous à faire mieux !',
                        placeholder:
                            'Expliquez brièvement comment nous pouvons améliorer ce point pour regagner ces étoiles au plus vite :)',
                    },
                    sentences: {
                        '1': 'mauvais',
                        '2': 'Bof...',
                        '3': 'Pas mal',
                        '4': 'Bonne',
                        '5': 'Génial !',
                    },
                    recommendation: {
                        title: 'Sur la base de cette formation, quelle est la probabilité que vous recommandiez Santé Académie à un(e) autre IDEL ?',
                        details: '',
                    },
                    expectations: {
                        title: 'La formation a-t-elle répondu à vos attentes ?',
                        details: '',
                    },
                    goals: {
                        title: 'La formation était-elle conforme aux objectifs annoncés ?',
                        details: '',
                    },
                    quizRelevance: {
                        title: 'Les quizz étaient-ils pertinents ?',
                        details: '',
                    },
                    resourceRelevance: {
                        title: 'Les ressources complémentaires étaient-elles utiles ?',
                        details: '',
                    },
                },
                courseIssueEncounter: {
                    title: 'Des problèmes en cours de route ?',
                    explanations: 'Quels sont les problèmes que vous avez rencontrés au cours de votre formation ?',
                    sentences: {
                        registration: 'Des problèmes liés à l’inscription à la formation',
                        content: 'Des questions liées au contenu pédagogique',
                        platform: 'Des problèmes techniques liés à l’usage de la plateforme',
                        none: 'Je n’ai eu ni problème ni question',
                        others: 'Autres (précisez)',
                    },
                    comment: {
                        placeholder: '',
                    },
                },
                courseIssueHelper: {
                    title: 'A-t-on réussi à vous aider ?',
                    explanations: 'Quels éléments vous ont aidé à répondre à vos questions ?',
                    sentences: {
                        forum: 'Forum',
                        online: 'Aide en ligne',
                        others: 'Autres (précisez)',
                    },
                    comment: {
                        placeholder: '',
                    },
                },
                courseImprovmentTextAdvice: {
                    title: "Des idées d'améliorations ?",
                    explanations: "Avez-vous des remarques pour nous permettre d'améliorer la formation ?",
                    placeholder: '',
                },
                newCourseIdeaTextAdvice: {
                    title: 'Votre prochaine formation ?',
                    explanations: 'Quels seraient vos besoins/idées pour vos futures formations ?',
                    placeholder: '',
                },
                recommendation: {
                    titleRecommendation: 'Envie de parler de nous ?',
                    explanationsRecommendation:
                        'Quelle est la probabilité que vous recommandiez Santé Académie à un•e collègue, confrère ou consœur ?',
                    values: {
                        '0': 'Mmh... probablement pas !',
                        '1': 'Mmh... probablement pas !',
                        '2': 'Mmh... probablement pas !',
                        '3': 'Mmh... probablement pas !',
                        '4': 'Mmh... probablement pas !',
                        '5': 'Mmh... probablement pas !',
                        '6': 'Mmh... probablement pas !',
                        '7': 'Pourquoi pas ?',
                        '8': 'Pourquoi pas ?',
                        '9': 'Très probable !',
                        '10': 'Très probable !',
                    },
                    titleEducationalFormat: 'Une dernière pour la route !',
                    explanationsEducationalFormat:
                        'Quels formats auriez-vous envie de suivre lors de vos prochaines formations ?',
                    sentences: {
                        remote_elearning: 'E-learning',
                        physical_classroom: 'Présentiel',
                        virtual_classroom: 'Classe virtuelle',
                        i_dont_know: 'Je ne sais pas'
                    },
                },
                finish: {
                    title: 'Merci, votre aide nous est précieuse !',
                    thanks: 'C’est grâce à vos retours que nous pouvons créer les meilleures formations du monde 🌍',
                    backHome: 'Retour à mes formations',
                    registrationCompletedTitle: 'Félicitations et merci de votre participation !',
                    registrationCompletedText: 'Merci d\'avoir suivi notre formation ! Découvrez nos offres exclusives pour aller encore plus loin.',
                    catalog: "Voir le catalogue de formation"
                },
            },
        },
    },
    card: {
        status: {
            ongoing: 'En cours',
        },
    },
    time: {
        second: 'seconde',
        minute: 'minute',
        hour: 'heure',
        day: 'jour',
        days: 'jours',
        weeks: 'semaines',
        months: 'mois',
    },
    video: {
        quality: {
            low: 'Basse définition',
            mid: 'Moyenne définition',
            high: 'Haute définition',
        },
    },
};
